<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="手机号"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入手机号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    type="index"
                    width="50"
                />
                <el-table-column
                    prop="mobile"
                    label="手机号"
                    min-width="100"
                />
                <el-table-column
                    label="分销信息"
                    min-width="100"
                >
                    <template
                        slot-scope="scope"
                    >
                        {{ scope.row.distributorName }}<span v-if="scope.row.distributorLevelName">({{ scope.row.distributorLevelName }})</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否代理商"
                    min-width="100"
                >
                    <template
                        slot-scope="scope"
                    >
                        {{ scope.row.isDistributor? '是':'否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="storeName"
                    label="代理商店铺名称"
                    min-width="100"
                />
                <el-table-column
                    prop="orderNum"
                    label="店铺订单数"
                    min-width="100"
                />
                <el-table-column
                    prop="orderAmount"
                    label="店铺订单金额"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsCostAmount"
                    label="店铺订单成本"
                    min-width="100"
                />
                <el-table-column
                    prop="agentAddAmount"
                    label="店铺订单佣金"
                    min-width="100"
                />
                <el-table-column
                    prop="totalIncome"
                    label="店铺总佣金"
                    min-width="100"
                />
                <el-table-column
                    prop="storeAmount"
                    label="店铺佣金余额"
                    min-width="100"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'DistributorStoreStat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                statDateDay: '',
                mobile: '',
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.DistributorStat.distributorStoreStatList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
    },
    created() {
        this.queryFormModel.statDateDay = [this.$utils.Common.getSysDayDate(), this.$utils.Common.getSysDayDate()];
    },
};
</script>

<style lang="scss" scoped>
</style>
