import { render, staticRenderFns } from "./distributorWithdrawStatList.vue?vue&type=template&id=51280ccf&scoped=true&"
import script from "./distributorWithdrawStatList.vue?vue&type=script&lang=js&"
export * from "./distributorWithdrawStatList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51280ccf",
  null
  
)

export default component.exports